<template>
	<v-container class="mt-4 px-5" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Resultados - Avance</span>
	  		    <v-spacer></v-spacer>
	  		  </v-card-title>
	  		  <v-card-text>

	  		  	<v-btn color="success" 
	  		  		tile
	  		  		small
	  		  		v-if="habil && resultadosLlenos"
	  		  		dark
	  		  		@click="verRegistroFinal( )"
	  		  	>
	  		  		<v-icon small left>mdi-eye</v-icon>
	  		  		Ver mi registro
	  		  	</v-btn>
	  		  	
	  		  	<v-btn color="red" 
	  		  		tile
	  		  		small
	  		  		v-if="habil && !resultadosLlenos"
	  		  		dark
	  		  		@click="llenarRegistro( )"
	  		  	>
	  		  		<v-icon small left>mdi-plus</v-icon>
	  		  		Llenar registro
	  		  	</v-btn>
	  		  	
	  		  	<v-btn color="orange" 
	  		  		tile
	  		  		small
	  		  		v-if="!habil && ultimoRegistro"
	  		  		dark
	  		  		@click="verRegistro( )"
	  		  	>
	  		  		<v-icon small left>mdi-eye</v-icon>
	  		  		Ver registro
	  		  	</v-btn>

	  		  	<v-btn color="red" 
	  		  		tile
	  		  		small
	  		  		v-if="!habil && !ultimoRegistro"
	  		  		dark
	  		  	>
	  		  		<v-icon small left>mdi-eye</v-icon>
	  		  		Sin registros
	  		  	</v-btn>
	  		  </v-card-text>
						
					<v-card-text>
						<v-tabs v-model="tabs">
		        	<v-tab class="text-capitalize" v-for="(tab, i) in menu" :key="i">
				        <v-icon left v-text="tab.icono"></v-icon>
				        {{ tab.text }}
				      </v-tab>

				      <!-- Tutro -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text>
				            <ResulHistorial v-if="tabs == 0" :colaboradores="colaboradores"/>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>
				      
				      <!-- información del grupo -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text class="pt-0">
				          	<ResulColaboradores  v-if="tabs == 1" :colaboradores="colaboradores" :fechas_resultados="fechas_resultados" @click="initialize()"/> 
					        </v-card-text>
				        </v-card>
				      </v-tab-item>
				    </v-tabs>
					</v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

		<v-row>
			<v-col cols="12">
			</v-col>
		</v-row>	  

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1"> REGISTRO - {{ editedItem.fecha_creacion }} </span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idresultados_personal }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
          	<v-col cols="12">
              <v-text-field
                v-model="editedItem.objetivo"
                label="Objetivo"
                filled
                hide-details
		            :readonly="agregarRegistro ? false : true"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" v-for="( actividad, i ) in editedItem.actividades" :key="i">

            	<v-row justify="start">
            		<v-col cols="11">
		              <v-textarea
		                v-model="editedItem.actividades[i].actividad"
		                label="Actividad"
		                outlined
		                dense
		                auto-grow
		                rows="2"
		                hide-details
		                :readonly="agregarRegistro ? false : true"
		              ></v-textarea>
            		</v-col>
            	</v-row>

            	<v-row justify="end" v-if="agregarRegistro && editedItem.actividades[i].retroalimentacion != '' ">
            		<v-col cols="11">
		              <v-textarea
		                v-model="editedItem.actividades[i].retroalimentacion"
		                label="Retroalimentación"
		                outlined
		                dense
		                auto-grow
		                rows="2"
		                hide-details
		                :readonly="agregarRegistro ? false : true "
		              ></v-textarea>
            		</v-col>
            	</v-row>

            </v-col>

          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            small
            dark
            @click="close"
          >
          	<v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            tile
            small
            dark
            @click="save"
            v-if="agregarRegistro"
          >
          	<v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import validarErrores        from '@/mixins/validarErrores'

  import ResulColaboradores    from '@/components/resultados/ResulColaboradores.vue';
  import ResulHistorial        from '@/components/resultados/ResulHistorial.vue';


  export default {
  	components:{
      Alerta,
      carga,
      ResulColaboradores,
			ResulHistorial
    },

    mixins: [ validarErrores ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	tabs: 0, 

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        idresultados_personal:0,
				idcolaborador: 0,
				idjefe: 0,
				objetivo:'',
				estatus:0,
				fecha_creacion:null,
				deleted:0,
				actividades:[
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
				],
      },

      defaultItem: {
        idresultados_personal:0,
				idcolaborador: 0,
				idjefe: 0,
				objetivo:'',
				estatus:0,
				fecha_creacion:null,
				deleted:0,
				actividades:[
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
				],
      },

      // Filtros: 
      incio:null,
			fin:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      formaspago: [],
      headers: [
        { text: 'ID'               , value: 'id_forma_pago'       },
        { text: 'Curso'            , value: 'forma_pago'          },
        { text: 'Activo'           , value: 'activo_sn'      },
        { text: 'Alta'             , value: 'fecha_alta'     },
        { text: 'Baja'             , value: 'fecha_baja'     },
        { text: 'Actions'          , value: 'actions', sortable: false },
      ],


      colaboradores    : [],
			habil            : false,
			ultimoRegistro   : null,
			resultadosLlenos : null,
			agregarRegistro  : true,

			menu:[],
			fechas_resultados:[],

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Forma de pago' : 'Editar forma de pago'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      "editedItem.numero_niveles"( val){
      	this.editedItem.precio_nivel = (this.editedItem.precio_total / val ).toFixed(2)
      },

      "editedItem.precio_total"( val){
      	this.editedItem.precio_nivel = (val / this.editedItem.numero_niveles).toFixed(2)
      },

    },

    async created () {
      await this.initialize()
    },

    methods: {

    	// Datos iniciales, obtener datos del organigrama, obtener la fecha de hoy, mi ultimo resultado, mis colaboradores, mi historial y más
      initialize () {
      	this.cargar = true
        this.formaspago = []

        return this.$http.get('resultados.usuarios/' + this.getdatosUsuario.iderp ).then(response=>{

        	this.colaboradores     = response.data.colaboradores
					this.habil             = response.data.habil
					this.ultimoRegistro    = response.data.ultimoRegistro
					this.resultadosLlenos  = response.data.resultadosLlenos
					this.fechas_resultados = response.data.fechas_resultados

					if( this.colaboradores.length )
						this.menu = [{text:'Historial',icono:'mdi-account'}, {text:'Colaboradores',icono:'mdi-account'}]
					else
						this.menu = [{text:'Historial',icono:'mdi-account'}]


        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      verRegistro( ){
      	this.agregarRegistro = false
        this.editedItem      = Object.assign({}, this.ultimoRegistro)
        this.dialog          = true
      },

      verRegistroFinal( ){
      	this.agregarRegistro = false
        this.editedItem      = Object.assign({}, this.resultadosLlenos)
        this.dialog          = true
      },

      llenarRegistro( ){
      	this.agregarRegistro = true
        this.dialog = true
      },

      editItem (item) {
        this.editedIndex = this.formaspago.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.formaspago.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('formaspago.eliminar/' + this.editedItem.id_forma_pago, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(this.editedItem.horario == ''){
      		this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio
      	this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('formaspago.update/' + this.editedItem.id_forma_pago, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
        		this.close()
          	this.initialize()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
	        this.editedItem.idcolaborador = this.getdatosUsuario.iderp 

          this.$http.post('resultados.add', this.editedItem).then(response=>{
          	this.initialize()
        		this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },
    },
  }
</script>


