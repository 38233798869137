<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Resultados - Avance</span>
	  		    <v-spacer></v-spacer>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="space-between">

	  		  		<v-col cols="12" md="6" lg="4">
	  		  			<v-radio-group
						      v-model="estatus"
						      row
						    >
						      <v-radio
						        label="Todos"
						        :value="2"
						      ></v-radio>
						      <v-radio
						        label="Pendiente"
						        :value="0"
						      ></v-radio>
						      <v-radio
						        label="Revisados"
						        :value="1"
						      ></v-radio>
						    </v-radio-group>
						  </v-col>

	  		  		<v-col cols="12" md="6" lg="4">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>

	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="filterResultados"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.estatus="{ item }">
							      <v-chip 
							      	v-if="item.estatus == 1"
							      	small
							      	color="green"
							      	dark
							      >Revisada</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >Pendiente</v-chip>
							    </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-eye</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1"> REGISTRO - {{ editedItem.fecha_creacion }} </span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idresultados_personal }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
          	<v-col cols="12">
              <v-text-field
                v-model="editedItem.objetivo"
                label="Objetivo"
                filled
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" v-for="( actividad, i ) in editedItem.actividades" :key="i">

            	<v-row justify="start">
            		<v-col cols="11">
		              <v-textarea
		                v-model="editedItem.actividades[i].actividad"
		                label="Actividad"
		                outlined
		                dense
		                auto-grow
		                rows="2"
		                hide-details
		                readonly
		              ></v-textarea>
            		</v-col>
            	</v-row>

            	<v-row justify="end">
            		<v-col cols="11">
		              <v-textarea
		                v-model="editedItem.actividades[i].retroalimentacion"
		                label="Retroalimentación"
		                outlined
		                dense
		                auto-grow
		                rows="2"
		                hide-details
		                readonly
		              ></v-textarea>
            		</v-col>
            	</v-row>

            </v-col>

          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="black"
            tile
            small
            dark
            @click="close"
          >
          	<v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import validarErrores        from '@/mixins/validarErrores'

  import ResulColaboradores    from '@/components/resultados/ResulColaboradores.vue';
  import ResulHistorial        from '@/components/resultados/ResulHistorial.vue';


  export default {
  	components:{
      Alerta,
      carga,
      ResulColaboradores,
			ResulHistorial
    },

    mixins: [ validarErrores ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	tabs: 0, 
    	expanded: [],

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        idresultados_personal:0,
				idcolaborador: 0,
				idjefe: 0,
				objetivo:'',
				estatus:0,
				fecha_creacion:null,
				deleted:0,
				actividades:[
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
				],
      },

      defaultItem: {
        idresultados_personal:0,
				idcolaborador: 0,
				idjefe: 0,
				objetivo:'',
				estatus:0,
				fecha_creacion:null,
				deleted:0,
				actividades:[
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
				],
      },

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      formaspago: [],
      headers: [
        { text: 'ID'     , value: 'idresultados_personal' },
        { text: 'Curso'  , value: 'objetivo'              },
        { text: 'Activo' , value: 'estatus'               },
        { text: 'Ragno'  , value: 'fecha_formato'         },
        { text: 'Alta'   , value: 'fecha_creacion'        },
        { text: 'Ver'    , value: 'actions' },
      ],

      estatus: 2,

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

    	filterResultados( ){
      	let data = this.historial

      	if( [0,1].includes( this.estatus ) ){
      		data = data.filter( el => { return el.estatus == this.estatus })
      	}

      	return data
      }
    },

    watch: {
    },

    async created () {
      await this.initialize()
    },

    methods: {

    	// Datos iniciales, obtener datos del organigrama, obtener la fecha de hoy, mi ultimo resultado, mis colaboradores, mi historial y más
      initialize () {
      	this.cargar = true
        this.historial = []

        return this.$http.get('resultados.historial/' + this.getdatosUsuario.iderp ).then(response=>{

					this.historial = response.data

        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem( value ){
        this.editedItem = Object.assign({}, value)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
    },
  }
</script>


