<template>
  <v-container class="mt-4 ml-2" fluid>
    <!-- Dialogo de editar o agregar-->
    <v-dialog
      v-model="dialogActas.estatus"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>ID: {{ editedItem.idactas_admin }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            filled
            dense
            clearable
            v-model="editedItem.id_usuario"
            :items="usuarios"
            label="Selecciona un usuario"
            item-text="nombre_completo"
            item-value="id_usuario"
            readonly
          >
          </v-autocomplete>

          <v-autocomplete
            filled
            dense
            clearable
            v-model="editedItem.id_faltas"
            :items="faltas"
            label="Selecciona una falta"
            item-text="falta"
            item-value="idfaltas"
          >
          </v-autocomplete>

          <v-autocomplete
            filled
            dense
            clearable
            v-model="editedItem.id_sancion"
            :items="sanciones"
            label="Selecciona una sanción"
            item-text="sancion"
            item-value="idsancion"
          >
          </v-autocomplete>

          <v-textarea
            v-model="editedItem.motivo"
            filled
            rows="3"
            label="Agrega una descripción"
          >
          </v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red"
            rounded
            dark
            small
            @click="dialogActas.estatus = false"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            color="primary"
            class="elevation-6"
            dark
            small
            @click="save"
            v-if="editedItem.id_usuario"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>

	    <!-- Dialog de carga -->
	    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
	    <carga                v-if="cargar"/>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import validarErrores        from '@/mixins/validarErrores'


  export default {
    components:{
      Alerta,
      carga,
    },

    props:['dialogActas', 'usuario'],

    mixins: [ validarErrores ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        idactas_admin:0,
        id_asigno:0,
        id_usuario:0,
        motivo:'',
        id_sancion:0,
        id_faltas:0,
      },

      defaultItem: {
        idactas_admin:0,
        id_asigno:0,
        id_usuario:0,
        motivo:'',
        id_sancion:0,
        id_faltas:0,
      },

      // Filtros: 
      incio:null,
      fin:null,

      // tabla
      search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      actas: [],
      headers: [
        { text: 'ID'               , value: 'idactas_admin'  },
        { text: 'Empleado'         , value: 'asignado'       },
        { text: 'Falta'            , value: 'falta'          },
        { text: 'Sanción'          , value: 'sancion'        },
        { text: 'Motivo'           , value: 'motivo'         },
        { text: 'Estatus'          , value: 'estatus'        },
        { text: 'fecha creación'   , value: 'fecha_creacion' },
        { text: 'Actions'          , value: 'actions', sortable: false },
      ],

      faltas:[],
      sanciones:[],
      usuarios:[]
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar actas admin.' : 'Editar actas admin.'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      
      'editedItem.id_usuario'( val ){
      	console.log( val)

      }
    },

    async created () {
    	this.editedItem.id_usuario = this.usuario.idcolaborador
    	console.log( this.usuario )
      await this.initialize()      
      await this.getFaltas()
      await this.getSanciones()
      await this.getUsuarios()

    },

    methods: {
      initialize () {
        this.cargar = true
        this.actas = []
        return this.$http.get('actas.list').then(response=>{
          this.actas = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getFaltas () {
        this.cargar = true
        this.faltas = []
        return this.$http.get('faltas.admin.list').then(response=>{
          this.faltas = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getSanciones () {
        this.cargar = true
        this.sanciones = []
        return this.$http.get('sanciones.admin.list').then(response=>{
          this.sanciones = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsuarios () {
        this.cargar = true
        this.usuarios = []
        return this.$http.get('usuarios.all.erp').then(response=>{
          this.usuarios = response.data

          for(const i in this.usuarios){
            this.usuarios[i].nombre_completo = this.usuarios[i].nombre_completo.toUpperCase() 
          }

          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      close () {
      	this.dialogActas.estatus = false
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        // Cargamos al usuario que hizo el cambio
        this.cargar = true
        
        this.editedItem.id_asigno = this.getdatosUsuario.iderp
        // Lo mandapos por el EP
        this.$http.post('actas.add', this.editedItem).then(response=>{
          this.cargar = false
          this.close()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  }
</script>


