<template>
	<v-container class="" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Resultados - Avance</span>
	  		    <v-spacer></v-spacer>
	  		    <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="initialize()"
              small
              tile
            >
            	<v-icon small left>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
	  		  </v-card-title>

	  		  <!-- CONTENIDO -->
	  		  <v-card-text>

	  		  	<!-- VER LA PARTE DE LOS COLABORADORES -->
	  		  	<v-row>
	  		  		<v-col cols="12" md="8" lg="5">
	  		  			<v-autocomplete
		              filled
		              dense
		              clearable
		              v-model="colaborador"
		              :items="colaboradores"
		              label="Selecciona un colarador"
		              persistent-hint
		              hide-details
		              item-text="nombre"
		              item-value="iderp"
		            >
		            </v-autocomplete>

	  		  		</v-col>

	  		  		<v-col cols="12" md="4" lg="4">
	  		  			<v-autocomplete
		              filled
		              dense
		              clearable
		              v-model="fecha_resultado"
		              :items="fechas_resultados"
		              label="Selecciona una fecha"
		              persistent-hint
		              hide-details
		              item-text="fecha_formato"
		              item-value="idfechas_resultados"
		            >
		            </v-autocomplete>

	  		  		</v-col>

	  		  		<v-col cols="12">
	  		  			<v-radio-group
						      v-model="estatus"
						      row
						    >
						      <v-radio
						        label="Todos"
						        :value="2"
						      ></v-radio>
						      <v-radio
						        label="Pendiente"
						        :value="0"
						      ></v-radio>
						      <v-radio
						        label="Revisados"
						        :value="1"
						      ></v-radio>
						    </v-radio-group>
	  		  		</v-col>

	  		  	</v-row>

	  		  	<v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="filterResultados"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
			            item-class="clase_row"
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.estatus="{ item }">
							      <v-chip 
							      	v-if="item.estatus == 1"
							      	small
							      	color="green"
							      	dark
							      >Revisada</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >Pendiente</v-chip>
							    </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    		v-if="item.clase_row == ''"
							    	>
								      <v-icon small>mdi-eye</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="abrirActa(item)"
							    		class="mr-2"
							    		v-if="item.clase_row == 'usuario_no_registro'"
							    	>
								      <v-icon small>mdi-file-document-edit</v-icon>
							    	</v-btn>
							    </template>

							    

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>


	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1"> REGISTRO - {{ editedItem.fecha_creacion }} </span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idresultados_personal }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
          	<v-col cols="12">
              <v-text-field
                v-model="editedItem.objetivo"
                label="Objetivo"
                filled
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" v-for="( actividad, i ) in editedItem.actividades" :key="i">

            	<v-row justify="start">
            		<v-col cols="11">
		              <v-textarea
		                v-model="editedItem.actividades[i].actividad"
		                label="Actividad"
		                outlined
		                dense
		                auto-grow
		                rows="2"
		                hide-details
		                readonly
		              ></v-textarea>
            		</v-col>
            	</v-row>

            	<v-row justify="end">
            		<v-col cols="11">
		              <v-textarea
		                v-model="editedItem.actividades[i].retroalimentacion"
		                label="Retroalimentación"
		                outlined
		                dense
		                auto-grow
		                rows="2"
		                hide-details
		              ></v-textarea>
            		</v-col>
            	</v-row>

            </v-col>

          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="black"
            tile
            small
            dark
            @click="close"
          >
          	<v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            tile
            small
            dark
            @click="save"
          >
          	<v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <NuevaActa            v-if="dialogActas.estatus"   :dialogActas="dialogActas" :usuario="usuario"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import validarErrores        from '@/mixins/validarErrores'

  import ResulColaboradores    from '@/components/resultados/ResulColaboradores.vue';
  import ResulHistorial        from '@/components/resultados/ResulHistorial.vue';
  import NuevaActa             from '@/components/resultados/NuevaActa.vue';


  export default {
  	components:{
      Alerta,
      carga,
      ResulColaboradores,
			ResulHistorial,
			NuevaActa
    },

    mixins: [ validarErrores ],

    props:[ 
    	'colaboradores',
    	'fechas_resultados'
    ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	tabs: 0, 

      dialog: false,

      editedItem: {
        idresultados_personal:0,
				idcolaborador: 0,
				idjefe: 0,
				objetivo:'',
				estatus:0,
				fecha_creacion:null,
				deleted:0,
				actividades:[
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
				],
      },

      defaultItem: {
        idresultados_personal:0,
				idcolaborador: 0,
				idjefe: 0,
				objetivo:'',
				estatus:0,
				fecha_creacion:null,
				deleted:0,
				actividades:[
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
					{ actividad: '', retroalimentacion: '' },
				],
      },

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      formaspago: [],
      headers: [
        { text: 'ID'           , value: 'idresultados_personal' },
        { text: 'colaborador'  , value: 'nombre'                },
        { text: 'Puesto'       , value: 'puesto'                },
        { text: 'Curso'        , value: 'objetivo'              },
        { text: 'Activo'       , value: 'estatus'               },
        { text: 'Rango'        , value: 'fecha_formato'         },
        { text: 'Alta'         , value: 'fecha_creacion'        },
        { text: 'Ver'          , value: 'actions'               },
      ],

      resultados:[],

      verRegistro: false,
      fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      colaborador: null,
      fecha_resultado: null,
      estatus:2,

      dialogActas:{
      	estatus: false,
      },
			usuario: null
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Forma de pago' : 'Editar forma de pago'
      },

      filterResultados( ){
      	let data = this.resultados

      	if( this.colaborador ){
      		data = data.filter( el =>{ return el.idcolaborador == this.colaborador })
      	}

      	if( this.fecha_resultado ){
      		data = data.filter( el =>{ return el.idfechas == this.fecha_resultado })
      	}

      	if( [0,1].includes( this.estatus ) ){
      		data = data.filter( el => { return el.estatus == this.estatus })
      	}

      	return data
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created(){
    	// this.initialize()
    },

    methods: {

    	// Datos iniciales, obtener datos del organigrama, obtener la fecha de hoy, mi ultimo resultado, mis colaboradores, mi historial y más
      initialize () {

      	if( !this.colaborador && !this.fecha_resultado ){ return this.validarErrorDirecto('Favor de seleccionar un colaborador o bien una fecha valida') }

      	this.cargar = true

      	const payload = {
      		colaboradores: this.colaboradores,
      		fecha:         this.fecha_resultado
      	}
        
        return this.$http.post('resultados.resultado' , payload ).then(response=>{
        	this.resultados    = response.data
        	this.cargar        = false
        }).catch( error =>{
        	this.verRegistro   = false
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      save () {
      	// Cargamos al usuario que hizo el cambio
      	this.editedItem['idjefe'] = this.getdatosUsuario.iderp
	      this.cargar = true

        // Lo mandapos por el EP
        this.$http.put('resultados.update/' + this.editedItem.idresultados_personal, this.editedItem).then(response=>{
        	this.validarSuccess( response.data.message )
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      editItem( value ){
        this.editedItem = Object.assign({}, value)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      abrirActa( item ){
				this.usuario =  item
      	this.dialogActas.estatus = true
      },
    },
  }
</script>


<style>
  .usuario_no_registro{
  	background-color: #FF3030 !important;
  	color: white;
  }
</style>